import React from 'react';
import { graphql } from "gatsby"
import Layout from '../components/layout';
import DynamicSections from '../components/dynamicSections';
import Hero from '../components/hero'

const AboutPage = ({ data }) => (
  <Layout title='DPM - About Us' description="DPM's ongoing success is due to the talent, passion and relentless curiosity of our people. Property Managers and Professionals you can trust will get the job done">
    <Hero
      content={
        <h2 className="title" style={{ textTransform: 'lowercase' }}>
          About.
          </h2>
      }
      additionalClasses="is-large"
    />
    <DynamicSections sections={data.pagedata.sections} />
  </Layout>
);

export const query = graphql`
  query{
    pagedata: strapiPage(strapiId: { eq: 1 }) {
      sections {
        id
        columnsPerRow
        columnTypes
        priority
        anchor_id
        column {
          content
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1600, maxHeight: 600, quality: 70, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          heroImage: image {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 70, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          members {
            id
            Email
            Name
            Position
            phone_number
            phone_number_linkable
            priority
            Description
            avatar {
              childImageSharp {
                  fluid(maxWidth: 1000, quality: 70) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
            }
          }
          columnsPerRow
          note
          element {
            number
            description
            icon {
              publicURL
            }
          }
        }
      }
    }
  }
`
export default AboutPage;
